<template>
  <div class="home-page column">
    <div class="block">
      <div class="container">
        <div class="spacer px-15">
          <h1>
            Domeinnamen,<br>hosting,<br>virtual servers:<br><strong class="primary-dark-text">online gemak!</strong>
          </h1>
        </div>
        <div class="spacer column px-15">
          <h2 style="margin-bottom: 40px;">
            Daarom kiest u voor<br><strong>Hosting On Demand</strong>
          </h2>
          <img
            style="margin-left: auto;"
            src="../../assets/images/daarom-wod.png"
            width="400"
            alt="Daarom Hosting On Demand"
          >
        </div>
      </div>
    </div>
    <div class="block green">
      <div class="container">
        <div class="column spacer px-15">
          <h2
            style="margin-bottom: 40px;"
          >
            Webhosting<br><strong>precies wat u nodig heeft</strong>
          </h2>
          <div class="s-column spacer">
            <img
              src="../../assets/images/dozen.png"
              height="190"
              width="254"
            >
            <div class="column align-end px-15">
              <p
                style="font-size: 15px; margin-bottom: 40px; text-align: right;"
                class="primary-dark-text"
              >
                <strong>Kies voor het webhostingpakket dat met u meegroeit en betaal alleen voor wat u nodig heeft.</strong>
              </p>
              <router-link class="button" to="/webhosting/">Info en bestellen</router-link>
            </div>
          </div>
          <ul style="margin: 20px 0 10px 0;">
            <li><router-link to="/klantenservice/veel-gestelde-vragen/">Alle vragen en antwoorden over hosting</router-link></li>
          </ul>
        </div>
        <div class="spacer">
          <h2
            style="margin-bottom: 40px;"
          >
            Uw eigen website<br><strong>Zelf bouwen en beheren</strong>
          </h2>
          <div class="s-column spacer">
            <img
              src="../../assets/images/desktop.png"
              height="190"
              width="254"
            >
            <div class="column align-end px-15">
              <p
                style="font-size: 15px; margin-bottom: 40px; text-align: right;"
                class="primary-dark-text"
              >
                <strong>Uw website in een gebruiksvriendelijk CMS. Zelf beheren, zonder technische kennis.</strong>
              </p>
              <router-link class="button" to="/">Info en bestellen</router-link>
            </div>
          </div>
          <ul style="margin: 20px 0 10px 0;">
            <li><router-link to="/klantenservice/veel-gestelde-vragen/">Alle vragen en antwoorden over de websitemaker</router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <div class="column spacer">
          <h2>Shared Servers</h2>
          <p class="subtitle">Snelle Shared Servers op basis van uw actuele behoeften</p>
          <p>Shared Servers op de snelste en meest betrouwbare hardware met uitstekende service. Een Shared Webserver voor elke toepassing.</p>
          <Prices :items="data.staticData.prices.sharedServersPrices"/>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <div class="column spacer">
          <h2>Cloud Servers</h2>
          <p class="subtitle">Snelle Cloud Servers op basis van uw actuele behoeften</p>
          <p>Managed Cloud Servers met minimaal SLA-niveau Brons. De snelste en meest betrouwbare hardware voor up-to-date cloudhosting met uitstekende service. Kies uw cores, RAM, HDD en GB’s traffic voor een Cloud Server op maat.</p>
          <Prices
            style="margin: 40px 0;"
            background
            :items="data.staticData.prices.cloudServersItems"
          />
        </div>
      </div>
    </div>
    <div class="block green">
      <div class="container">
        <div class="column spacer">
          <h2>Direct aan de slag met meer dan 100 applicaties<br><strong>eenvoudig te installeren en meteen te gebruiken</strong></h2>
        </div>
      </div>
    </div>
    <div
      v-if="data && data.staticData && data.staticData.carousel && data.staticData.carousel.length"
      class="block"
    >
      <div class="container">
        <div class="column spacer">
          <h2>Echte klanten over onze service</h2>
          <div class="quote-carousel">
            <div class="quote-carousel-inner">
              <div
                v-for="(item, index) in data.staticData.carousel"
                :key="index"
                class="quote-carousel-item"
                :class="[
                  index === currentCarouselItemIndex ? ['active', carouselDirection] : '',
                  index === nextCarouselItemIndex ? carouselDirection : '',
                  index < currentCarouselItemIndex ? 'prev' : '',
                  index > currentCarouselItemIndex ? 'next' : '',
                ]"
              >
                <p>{{ item.text }}</p>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="quote-carousel-indicators">
            <img
              v-for="(item, index) in data.staticData.carousel"
              :key="index"
              :class="[index === currentCarouselItemIndex && nextCarouselItemIndex < 0 ? 'active' : '']"
              :src="require(`../../assets/images/${item.img}`)"
              @click="changeCurrentcarouselItem(index)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import Prices from '@/components/Prices.vue';

export default {
  name: 'Page',
  components: {
    Prices,
  },
  setup() {
    let carouselInterval = ref(null);
    onMounted(() => {
      if (data && data.staticData && data.staticData.carousel && data.staticData.carousel.length) {
        carouselInterval = setInterval(() => {
          autoChangeCurrentcarouselItem();
        }, 4000);
      }
    });

    const data = inject('data');

    // carousel logic
    const currentCarouselItemIndex = ref(0);
    const nextCarouselItemIndex = ref(-1);
    const carouselDirection = ref('');

    function autoChangeCurrentcarouselItem() {
      let newNextCarouselItemIndex = 0;

      if (currentCarouselItemIndex.value !== data.staticData.carousel.length - 1) {
        newNextCarouselItemIndex = currentCarouselItemIndex.value + 1;
      }

      changeCurrentcarouselItem(newNextCarouselItemIndex, false);
    }

    function changeCurrentcarouselItem(newNextCarouselItemIndex, byHand = true) {
      if (currentCarouselItemIndex.value !== newNextCarouselItemIndex) {
        nextCarouselItemIndex.value = newNextCarouselItemIndex;

        if (currentCarouselItemIndex.value < nextCarouselItemIndex.value) {
          carouselDirection.value = 'left';
        } else {
          carouselDirection.value = 'right';
        }

        setTimeout(() => {
          currentCarouselItemIndex.value = nextCarouselItemIndex.value;
          nextCarouselItemIndex.value = -1;
          carouselDirection.value = '';

          if (carouselInterval && byHand) {
            clearInterval(carouselInterval);
            carouselInterval = setInterval(() => {
              autoChangeCurrentcarouselItem();
            }, 4000);
          }
        }, 600);
      }
    }

    return {
      // store
      data,

      // data
      currentCarouselItemIndex,
      nextCarouselItemIndex,
      carouselDirection,

      // methods
      changeCurrentcarouselItem,
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  align-items: center;

  .block {
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background-color: var(--background);

    .subtitle {
      margin-bottom: 20px;
      font-weight: 200;
      line-height: 1.4;
      font-size: 21px;
    }

    .quote-carousel {
      position: relative;

      .quote-carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;

        .quote-carousel-item {
          position: relative;
          display: none;
          background: url('../../assets/images/quotes.png') no-repeat center top transparent;
          background-size: 100% auto;
          margin-bottom: 40px;

          p {
            font-family: 'Roboto Slab', serif;
            font-size: 50px;
            line-height: 60px;
            text-align: center;
            margin: 40px 25%;
            font-weight: 300;
            color: var(--primary-dark);
          }

          span {
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            width: 100%;
            display: block;
            text-transform: uppercase;
            color: var(--primary);
            font-size: 16px;
          }

          &.active {
            left: 0;
            transition: .6s ease-in-out left;

            &.left {
              left: -100%;
            }

            &.right {
              left: 100%;
            }
          }
        }
        .active, .next, .prev {
          display: block;
        }

        .left, .right {
          transition: .6s ease-in-out left;
        }

        .next, .prev {
          position: absolute;
          top: 0;
          width: 100%;
        }

        .next.left {
          left: 0;
        }

        .prev.right {
          left: 0;
        }

        .next {
          left: 100%;
        }

        .prev {
          left: -100%;
        }
      }
    }

    .quote-carousel-indicators {
      img {
        opacity: 0.5;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }
    }

    &.green {
      background-color: var(--primary-light);
    }
  }
}
</style>